.App {
  text-align: center;
  margin: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  margin: 30px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.uploadBundle {
  text-align: left;
  width: fit-content;
  margin: auto;
}

.uploadLabel {
  font-size: 16px;
  margin-right: 15px;
}

.uploadBundle > * {
    margin: 10px 0 0 0;
}

.uploadBundle input {
  float: right;
}

.tileRow {
  clear: both;
  min-height: 315px;
  display: flex;
  margin-bottom: 50px;
}
.leftTile {
  border: 1px solid white;
  border-radius: 15px;
  display: inline-block;
  width: 45%;
  position: relative;
  vertical-align: top;
  /*float: left;
  margin-bottom: 10px;*/
  min-height: 315px;
  background-color: white;
  /*margin-right: 20px;*/
  margin: auto;
  padding: 10px;
  box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.1);
}
.rightTile {
  border: 1px solid white;
  border-radius: 15px;
  display: inline-block;
  width: 45%;
  vertical-align: top;
  /*float: right;
  margin-bottom: 10px;*/
  position: relative;
  min-height: 315px;
  background-color: white;
  padding: 10px;
  margin: auto;
  box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.1);
}
.fullTile {
  margin: auto;
  min-width: 300px;
  border: 1px solid white;
  border-radius: 15px;
  display: inline-block;
  position: relative;
  vertical-align: top;
  height: 300px;
  background-color: white;
  padding: 10px;
  box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.1);
}

.tile_header_div {
  border-bottom: 1px solid lightgrey;
}
.tile_content_div {
  padding: 5px;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

button {
  margin: 10px;
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  cursor: pointer;
  transition: border-color 0.25s;
}
button:hover {
  border-color: #646cff;
}
button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

.rmFileIcon {
  color: red;
  font-weight: bolder;
  float: right;
}

.rmFileIcon:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  padding-top: 100px; /* Location of the box */
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
/* Modal Header */
.modal-header {
  padding: 2px 16px;
  background-color: #007dc3;
  color: white;
  height: 30px;
  padding: 10px;
}

/* Modal Body */
.modal-body {
  padding: 25px;
  max-height: 500px;
    overflow: auto;
}

/* Modal Footer */
.modal-footer {
  padding: 2px 16px;
  background-color: #007dc3;
  color: white;
  height: 35px;
  text-align: center;
  display: flex;
  align-items: center;
  text-align: center;
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: white;
  margin: auto;
  padding: 0;
  width: 75%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  animation-name: animatetop;
  animation-duration: 0.4s;
}

/* Add Animation */
@keyframes animatetop {
  from {top: -300px; opacity: 0}
  to {top: 0; opacity: 1}
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.popup {
  background-color: rgba(0, 0, 0, 0.1);
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: auto; */
  z-index: 1;
}

.access-code label {
  /*float: left;*/
}

.access-code input {
  /*float: right;*/
}